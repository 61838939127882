import React from "react"
import Meta from "../components/meta"
// import Fawzi from "../assets/fawzi.png"
// import Adham from "../assets/adham.jpg"
// import { FaLinkedin, FaEnvelope, FaRegIdCard, FaGithub } from "react-icons/fa"
// import useTidio from "../hooks/useTidio"
import GlobalHeader from "../components/globalHeader"
import GlobalFooter from "../components/globalFooter"

export default function About() {
  const block = "about"
  // useTidio(false)
  return (
    <div>
      <GlobalHeader />
      <div className={block}>
        <Meta
          title='Digital Touchless QR Menu — About'
          description='Digital Touchless QR Menu is a tool that allows you to create a mobile-friendly QR code menu for your restaurant during COVID-19.'
        />
        <h1>About us</h1>
        <div className={`${block}`}>
          <h2>What is DigitalQRMenu?</h2>
          <div className={`${block}__divider`}></div>
          <p>
            DigitalQRMenu is an initiative aimed at helping small and local
            restaurants during the COVID-19 pandemic. To reduce contact between
            employees and customers, many restaurants have been switching to
            contactless menus that customers can access through a QR code. We
            realized that it would be helpful to build a tool that made it easy
            for anyone to create a contactless menu for their restaurants. DigitalQRMenu is not a free service.
          </p>
        </div>

        <div className={`${block}`}>
          <h2>Why is it cheaper?</h2>
          <div className={`${block}__divider`}></div>
          <p>
            There are many services that are similar to DigitalQRMenu but they all
            charge a higher fee. Since restaurants have struggled financially
            during the pandemic, owners shouldn’t need an additional expense to worry about. By
            making reachable DigitalQRMenu, we're also encouraging business owners to
            switch to a contactless menu to create a safer dining experience for
            everyone.
          </p>
        </div>

        <div className={`${block}`}>
          <h2>What's our goal?</h2>
          <div className={`${block}__divider`}></div>
          <p>
            We would like to help millions of businesses create contactless menus,
            no matter where they are in the world. We realize that contactless
            menus are by no means a complete solution to the pandemic, but
            they’re a step in the right direction to reduce contact between
            customers and employees in indoor spaces.
          </p>
        </div>

        <div className={`${block}`}>
          <h2>What about the fee?</h2>
          <div className={`${block}__divider`}></div>
          <p>
          The fee is only monthly 8 GBP.
          </p>
        </div>
      </div>
      <GlobalFooter />
    </div>
  )
}
